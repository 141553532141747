import { useState } from 'react';
import axios from 'axios';
import { Form, Button, Col, InputGroup } from 'react-bootstrap';
import TermsAndConditions from '../TermsAndConditions/TermsAndConditions';

const StepOne = ({ formData, setFormData, nextStep }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [companySize, setCompanySize] = useState(50);
    const [termsStatus, setTermsStatus] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [error, setError] = useState('');
    const domainPrefix = 'myesky.ca';
    const countries = [
        "Afganistán", "Albania", "Argelia", "Andorra", "Angola", "Antigua y Barbuda", "Argentina",
        "Armenia", "Australia", "Austria", "Azerbaiyán", "Bahamas", "Baréin", "Bangladesh", "Barbados",
        "Bielorrusia", "Bélgica", "Belice", "Benín", "Bután", "Bolivia", "Bosnia y Herzegovina",
        "Botsuana", "Brasil", "Brunéi", "Bulgaria", "Burkina Faso", "Burundi", "Cabo Verde", "Camboya",
        "Camerún", "Canadá", "Catar", "Chad", "Chile", "China", "Colombia", "Comoras", "Congo (República del)",
        "Congo (República Democrática del)", "Costa Rica", "Croacia", "Cuba", "Chipre", "Chequia (República Checa)",
        "Dinamarca", "Djibouti", "Dominica", "Ecuador", "Egipto", "El Salvador", "Guinea Ecuatorial", "Eritrea",
        "Estonia", "Eswatini", "España", "Estados Unidos", "Etiopía", "Fiyi", "Filipinas", "Finlandia", "Francia",
        "Gabón", "Gambia", "Ghana", "Grecia", "Granada", "Guatemala", "Guinea", "Guinea-Bisáu", "Guyana", "Haití",
        "Honduras", "Hungría", "Islandia", "India", "Indonesia", "Irán", "Irak", "Irlanda", "Israel", "Italia",
        "Jamaica", "Japón", "Jordania", "Kazajistán", "Kenia", "Kirguistán", "Kiribati", "Corea del Norte",
        "Corea del Sur", "Kosovo", "Letonia", "Líbano", "Lesoto", "Liberia", "Libia", "Liechtenstein", "Lituania",
        "Luxemburgo", "Madagascar", "Malasia", "Malawi", "Maldivas", "Mali", "Malta", "Marruecos", "Mauricio",
        "Mauritania", "México", "Micronesia", "Moldavia", "Mónaco", "Mongolia", "Montenegro", "Mozambique",
        "Myanmar (Birmania)", "Namibia", "Nauru", "Nepal", "Nicaragua", "Níger", "Nigeria", "Noruega", "Nueva Zelanda",
        "Omán", "Países Bajos", "Pakistán", "Palau", "Panamá", "Papúa Nueva Guinea", "Paraguay", "Perú", "Polonia",
        "Portugal", "Ruanda", "Rumanía", "Rusia", "San Cristóbal y Nieves", "San Marino", "Santa Lucía",
        "San Tomé y Príncipe", "Senegal", "Serbia", "Seychelles", "Sierra Leona", "Singapur", "Siria", "Somalia",
        "Sudáfrica", "Sudán", "Sudán del Sur", "Suecia", "Suiza", "Taiwán", "Tanzania", "Tailandia", "Timor Oriental",
        "Togo", "Tonga", "Trinidad y Tobago", "Túnez", "Turquía", "Turkmenistán", "Tuvalu", "Uganda", "Ucrania",
        "Emiratos Árabes Unidos", "Reino Unido", "Uruguay", "Uzbekistán", "Vanuatu", "Venezuela", "Vietnam", "Yemen",
        "Zambia", "Zimbabue"
    ];

    const isOnlyLetters = (str) => str.length > 1 ? !(/^[a-zA-Z]+$/.test(formData.lmsUrl)) : false

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (e.target.name === 'companySize') {
            setCompanySize(e.target.value);
        }

        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!termsStatus) {
            setModalShow(true);
            return;
        }

        if (isOnlyLetters(formData.lmsUrl)) {
            return;
        }

        setError('');
        setIsLoading(true);
        try {
            const response = await axios.post(process.env.REACT_APP_API_SUBMIT, formData);
            if (response.data.success) {
                nextStep(1);
            } else {
                setError(response.data.error);
            }
        } catch (err) {
            setError('An error occurred. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Form onSubmit={handleSubmit} className='step-one-form'>
            <Form.Group as={Col} controlId="formName">
                <Form.Label column sm={10}>Name</Form.Label>
                <Col sm={12}>
                    <Form.Control type="text" name="name" value={formData.name} onChange={handleChange} required />
                </Col>
            </Form.Group>
            <Form.Group as={Col} controlId="formLastname">
                <Form.Label column sm={10}>Lastname</Form.Label>
                <Col sm={12}>
                    <Form.Control type="text" name="lastname" value={formData.lastname} onChange={handleChange} required />
                </Col>
            </Form.Group>
            <Form.Group as={Col} controlId="formEmail">
                <Form.Label column sm={10}>Email</Form.Label>
                <Col sm={12}>
                    <Form.Control type="email" name="email" value={formData.email} onChange={handleChange} required />
                </Col>
            </Form.Group>
            <Form.Group as={Col} controlId="formCompanyName">
                <Form.Label column sm={10}>Company Name</Form.Label>
                <Col sm={12}>
                    <Form.Control type="text" name="companyName" value={formData.companyName} onChange={handleChange} required />
                </Col>
            </Form.Group>
            <Form.Group as={Col} controlId="formCompanySize">
                <Form.Label column sm={10}>Company Size</Form.Label>
                <Col sm={12}>
                    <Form.Control type="range" name="companySize" min="1" max="1000" value={formData.companySize} onChange={handleChange} required />
                    <div className="range-label">
                        <span>1</span>
                        <Form.Text className="range-output">{companySize}</Form.Text>
                        <span>10000</span>
                    </div>
                </Col>
            </Form.Group>
            <Form.Group as={Col} controlId="formPhone">
                <Form.Label column sm={10}>Phone</Form.Label>
                <Col sm={12}>
                    <Form.Control type="text" name="phone" value={formData.phone} onChange={handleChange} required />
                </Col>
            </Form.Group>
            <Form.Group as={Col} controlId="formCountry">
                <Form.Label column sm={10}>Country</Form.Label>
                <Col sm={12}>
                    <Form.Control as="select" name="country" value={formData.country} onChange={handleChange} required>
                        <option value="">Select Country</option>
                        {countries.map((country) => {
                            return <option key={country} value={country}>{country}</option>
                        })}
                    </Form.Control>
                </Col>
            </Form.Group>
            <Form.Group as={Col} controlId="formLmsUrl">
                <Form.Label column sm={10}>LMS URL</Form.Label>
                <Col sm={12}>
                    <InputGroup>
                        <InputGroup.Text>
                            https://
                        </InputGroup.Text>
                        <Form.Control type="text" name="lmsUrl" value={formData.lmsUrl} onChange={handleChange} required isInvalid={isOnlyLetters(formData.lmsUrl)} />
                        <InputGroup.Text>{domainPrefix}</InputGroup.Text>
                        <Form.Control.Feedback type="invalid">
                            LMS name is invalid
                        </Form.Control.Feedback>
                    </InputGroup>
                </Col>
            </Form.Group>
            {error && <p style={{ color: 'red', gridColumn: 'span 2' }}>{error}</p>}
            <Form.Group as={Col} controlId="formTermsAndConditions" style={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: '14px' }}>
                <Form.Check
                    type='checkbox'
                    defaultChecked={termsStatus}
                    id='terms-and-conditions'
                    onChange={() => setTermsStatus(!termsStatus)}
                />
                <Form.Label column sm={10} style={{ display: 'flex' }}>
                    I accept the&nbsp;<strong style={{ cursor: 'pointer' }} onClick={() => setModalShow(true)}>terms and conditions</strong>
                </Form.Label>
            </Form.Group>
            <Button className='submit-step-1' type="submit" disabled={isLoading || !termsStatus}>
                {isLoading ? 'Submitting...' : 'Next'}
            </Button>

            <TermsAndConditions
                show={modalShow}
                onHide={() => {
                    !termsStatus ? setError('You must accept the terms and conditions') : setError('');
                    setModalShow(false)
                }}
                termsStatus={termsStatus}
                onChange={() => setTermsStatus(!termsStatus)}
            />
        </Form>
    );
};

export default StepOne