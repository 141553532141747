import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate, Navigate, useLocation } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import './StepperForm.css';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import StepperNavigation from './StepperNavigation';

const StepperForm = () => {
    const [platformUrl, setPlatformUrl] = useState('');
    const [currentStep, setCurrentStep] = useState(1);
    const [formData, setFormData] = useState({
        name: '',
        lastname: '',
        email: '',
        companyName: '',
        companySize: 50,
        phone: '',
        country: '',
        lmsUrl: ''
    });

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const stepFromUrl = location.pathname.split('/');
        const getStep = (element) => element === 'step';
        const stepPosition = stepFromUrl.findIndex(getStep) + 1;
        const stepNumber = parseInt(stepFromUrl[stepPosition]);
        if (!isNaN(stepNumber) && stepNumber >= 1 && stepNumber <= 3) {
            setCurrentStep(stepNumber);
        }
    }, [location]);

    const nextStep = (currentStep) => {
        const nextStep = currentStep + 1;
        let nextPath = `/step/${nextStep}`
        if (nextStep === 2) {
            nextPath += '?email=' + encodeURIComponent(formData.email);
        }

        navigate(nextPath);
    };

    const previousStep = (currentStep) => {
        const previousStep = currentStep - 1;
        navigate(`/step/${previousStep}`);
    };

    return (
        <Container>
            <Row>
                <Col>
                    <span>REQUEST A DEMO</span>
                    <h2>Unleash The Power Of Social Learning</h2>
                    <StepperNavigation currentStep={currentStep} />
                    <Routes>
                        <Route path="1" element={<StepOne formData={formData} setFormData={setFormData} nextStep={nextStep} />} />
                        <Route path="2" element={<StepTwo formData={formData} setPlatformUrl={setPlatformUrl} nextStep={nextStep} previousStep={previousStep} />} />
                        <Route path="3" element={<StepThree formData={formData} platformUrl={platformUrl} previousStep={previousStep} />} />
                        <Route path="*" element={<Navigate to="/step/1" />} />
                    </Routes>
                </Col>
            </Row>
        </Container>
    );
};
export default StepperForm;
