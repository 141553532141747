import { Route, Routes, Navigate } from 'react-router-dom';
import StepperForm from '../components/Stepper/StepperForm'
import FeaturesCard from '../components/FeaturesCard/FeaturesCard';
import MainSection from '../components/MainSection/MainSection';

const Homepage = () => {
	return (
		<>
			<MainSection>
				<div className='card-stepper-form'>
					<Routes>
						<Route path="step/*" element={<StepperForm />} />
						<Route path="/" element={<Navigate to="/step/1" replace />} />
					</Routes>
				</div>
			</MainSection>
			<FeaturesCard />
		</>
	)
}

export default Homepage