import './MainSection.css';

const MainSection = ({ children }) => {
    return (
        <section id="demo" className="main-section">
            {children}
            <div className="overlay"></div>
        </section>
    );
};

export default MainSection;
