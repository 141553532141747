import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import Spinner from '../Spinner/Spinner';

const StepThree = ({ formData, previousStep, platformUrl }) => {
    const [loading, setLoading] = useState(true);

    setTimeout(() => {
        setLoading(false);
    }, 4000);

    return (
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px' }}>
            {loading ?
                <>
                    <div style={{ gridColumn: '1 / 3' }}><Spinner /></div>
                    <span style={{ gridColumn: '1 / 3' }}>Generating platform...</span>
                </> :
                <>
                    <Button as={Link} to={platformUrl}>Access Platform</Button>
                    <Button as={Link} to={platformUrl ? `${platformUrl}/main/admin/register_template.php` : ''}>Customize</Button>
                </>
            }
        </div>
    );
};

export default StepThree;