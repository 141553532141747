import { Container } from "react-bootstrap";
import "./FeaturesCard.css";

const FeaturesCard = () => {
    const features = [
        {
            id: 1,
            imageUrl: "https://e-sky.ca/wp-content/uploads/revslider/home-one-1/home12-051.png",
            title: "Social Learning"
        },
        {
            id: 2,
            imageUrl: "https://e-sky.ca/wp-content/uploads/2021/09/demo13-success.svg",
            title: "Gamification"
        },
        {
            id: 3,
            imageUrl: "https://e-sky.ca/wp-content/uploads/2020/10/Creative-Minds.png",
            title: "Fully Customizable"
        },
        {
            id: 4,
            imageUrl: "https://e-sky.ca/wp-content/uploads/2020/10/Engineering-Services.png",
            title: "Reporting"
        },
        {
            id: 5,
            imageUrl: "https://e-sky.ca/wp-content/uploads/2023/01/AI-Chip.png",
            title: "A.I POWER Driven LMS"
        },
    ];

    return (
        <section id="solutions" className="advertisers-feature-sec pt-5 pb-5">
            <Container>
                <span>e-SKY solutions</span>
                <h2 className="text-center">Learn - Interact - Engage - Grow</h2>
                <p>
                    Most powerful social learning platform – a learning platform like no
                    other. Our team of technical experts can customize a Learning
                    Management System (LMS) in the cloud to suit your specific needs.
                    Moreover, our strategic business unit, e-SKY Production, specializes
                    in online instructional film production and can work with your team to
                    create custom courses specific for your organizations need.
                </p>
                <div className="cn-advertisers">
                    {features.map((feature) => {
                        return (
                            <div key={feature.id} className="feature-card">
                                <div className="icon-wrapper">
                                    <img src={feature.imageUrl} alt="Social Learning" />
                                </div>
                                <h3>{feature.title}</h3>
                            </div>
                        );
                    })}
                </div>
            </Container>
        </section>
    );
};

export default FeaturesCard;
