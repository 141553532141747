import { IconMapPin, IconMail, IconPhone, IconBrandFacebook, IconBrandTwitter, IconBrandLinkedin } from '@tabler/icons-react';
import Container from "react-bootstrap/Container";
import './Footer.css';
import skyLogoDark from "../../assets/sky-logo-dark.png";

const Footer = () => {
    const contactInfo = {
        location: "3551 Bd Saint-Charles #369, Kirkland, QC H9H 3C4",
        email: "info@e-sky.ca",
        phone: "+1 855-693-7591",
        facebook: "#",
        twitter: "#",
        linkedin: "#",
    };

    return (
        <footer className="footer">
            <Container className="footer-container">
                <img src={skyLogoDark} alt="esky logo" className="footer-logo" />
                <div className="cn-info-footer">
                    <h5>Contact</h5>
                    <hr />
                    <p>
                        <IconMapPin stroke={1} size={30} />
                        {contactInfo.location}
                    </p>
                    <p>
                        <IconMail stroke={1} size={30} />
                        {contactInfo.email}
                    </p>
                    <p>
                        <IconPhone stroke={1} size={30} />
                        {contactInfo.phone}
                    </p>
                </div>
                <div className="cn-info-footer">
                    <h5>Social networks</h5>
                    <hr />
                    <div className="cn-social-networks">
                        <a href={contactInfo.facebook}>
                            <IconBrandFacebook stroke={1} size={44} />
                        </a>
                        <a href={contactInfo.twitter}>
                            <IconBrandTwitter stroke={1} size={44} />
                        </a>
                        <a href={contactInfo.linkedin}>
                            <IconBrandLinkedin stroke={1} size={44} />
                        </a>
                    </div>
                </div>
            </Container>
        </footer>
    );
};

export default Footer;
