import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './TermsAndConditions.css';
import tc from './tc.json';

const TermsAndConditions = ({ show, onHide }) => {
    let description = tc.description;
    description = description.replace('{{ __DATE__ }}', new Date().toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" }));

    const renderList = (data) => {
        return (
            <ol>
                {data.map((item, index) => (
                    <li key={index}>
                        {item.title}
                        {item.description && <p>{item.description}</p>}
                        {item.childs && renderList(item.childs)}
                    </li>
                ))}
            </ol>
        );
    };

    return (
        <div>
            <Modal show={show} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body className='modal-body'>
                    <h3 className='content-title'>Terms & Conditions</h3>
                    <div className='content-terms'>
                        {description}
                        {tc.body.map((section, index) => (
                            <div key={index}>
                                <h4 style={{ margin: '10px 0px' }}>{section.title}</h4>
                                {renderList(section.childs)}
                            </div>
                        ))}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default TermsAndConditions
