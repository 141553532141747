import React from "react";
import { Navbar, Nav,Container } from 'react-bootstrap';
import Footer from './components/Footer/Footer';
import skyLogo from "./assets/sky-logo.png";
import Homepage from './Pages/Homepage';

const App = () => (
    <>
        <Navbar bg="light" data-bs-theme="light">
            <Container>
                <Navbar.Brand href="#home">
                    <img src={skyLogo} alt="sky logo" className="navbar-logo" />
                </Navbar.Brand>
                <Nav>
                    {/*<Nav.Link href="#demo">Demo</Nav.Link>*/}
                </Nav>
            </Container>
        </Navbar>
        <Homepage />
        <Footer />
    </>
);

export default App;
