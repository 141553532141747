import { IconUser, IconMail, IconBrowser } from '@tabler/icons-react';

const StepperNavigation = ({ currentStep }) => {
    return (
        <div className='container-stepper-navigation'>
            <hr />
            <div className='stepper-navigation'>
                <div className="step">
                    <IconUser color='white' className={currentStep === 1 ? 'icon' : 'icon stepper-active'} stroke={2} />
                    <span>
                        General information
                    </span>
                </div>
                <div className="step">
                    <IconMail color='white' className={currentStep === 2 ? 'icon' : 'icon stepper-active'} stroke={2} />
                    <span>
                        Verify your email
                    </span>
                </div>
                <div className="step">
                    <IconBrowser color='white' className={currentStep === 3 ? 'icon' : 'icon stepper-active'} stroke={2} />
                    <span>
                        Access your LMS
                    </span>
                </div>
            </div>
        </div>
    )
}

export default StepperNavigation
